<template>
  <div class="job-details">

    <!-- Intro -->
    <BasePageHeader header="Create Claim" :subHeader="(job && job.jobid ? job.jobid : '')" />
    <!-- Loading -->
    <BaseLoading v-if="loading && !error" class="container"/>
    <!-- Page Error -->
    <BaseWarning v-if="error" :warning="error" class="container-fluid">
      <template v-slot:append><router-link :to="`/job/` + job_id">Go Back</router-link></template>
    </BaseWarning>
     
    <!-- JOB LOADED -->
    <div v-if="!loading && job" class="content">

      <!-- Main -->
      <div class="container">
        <div class="row">
          <div class="col-md-8 bg-site-light py-3 rounded">

            <!-- INTRO -->
            <div class="p-3 mb-3" style="background: #631463aa; color: #fff;">
              <h5>Create new claim</h5>
              <p>You are about to begin the process of creating a new claim. Alfredssons will take care of the claims processing and will send a replacement or issue a refund.</p>
              <p>You are required to provide photo evidence that supports the claim (if needed): Transport label, package, products and possible flaws must be clearly visible in the pictures.</p>
            </div>

            <div class="p-3 mb-3" style="background: #dc0000ab; color: #fff;">
              <h5>TODO / QUESTIONS</h5>
              <ul>
                <li>Sub-reasons. What are they, and when can they be used?</li>
                <li>Wrong packaging: Is this really a "select content"-claim type of thing?</li>
                <li>Lost in Transit: Always a full claim? No need to change quantities (but keep it anyway just so we can)?</li>
              </ul>
            </div>

            <!-- STEP 1 - Basic claim properties -->
            <b-card
              border-variant="light"
              header="STEP 1"
              header-class="font-weight-bold"
              header-border-variant="secondary"
              header-bg-variant="white"
              header-text-variant="dark"
              align="left"
              class="mb-3"
            >
              <b-card-text>
                <!-- Reason dropdown -->
                <b-form-group label="Main reason for claim">
                  <b-form-select v-model="claim.reason" :options="reason_options" @change="claim.sub_reasons = []">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select a reason --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <!-- sub options depending on reason -->
                <b-form-group v-show="claim.reason" label="Please also select one or more specific reason(s).">
                  <b-form-checkbox-group
                    v-model="claim.sub_reasons"
                    :options="sub_reason_options"
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>
                
                <!-- comment -->
                <b-form-group label="Additional information" :description="`Optional information. ` + (250 - claim.comment.length) +` characters left.`">
                  <b-form-textarea
                    v-model="claim.comment"
                    placeholder="Optional information for customer service..."
                    rows="3"
                    max-rows="6"
                    maxlength="250"
                  ></b-form-textarea>
                </b-form-group>

                <!-- Refund switch -->
                <b-form-group description="Select to request refund instead of new items.">
                  <b-form-checkbox v-model="claim.refund">Refund</b-form-checkbox>
                </b-form-group>

              </b-card-text>
            </b-card>

            <!-- STEP 2 - Content with quantities dropdown as custom slot -->
            <transition name="fade">
              <b-card v-if="claim.reason" border-variant="light" header="STEP 2" header-class="font-weight-bold" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="mb-3">
                <b-card-text>
                  <h5>Please select quantities to represent claim.</h5>
                  <JobContentWithSlot :items="job.fulldata.items">
                    <!--
                      CUSTOM SLOT: This slot takes 'item' data from child,
                      and updates the selected claim value to this.claim.items[].claim_quantity
                    -->
                    <template v-slot:extra="{ item }">
                      <b-form-group 
                        label="Claim quantity:"
                        label-class="pb-1"
                        description="Please select claimed quantity for this item."
                        class="font-weight-bold mt-3"
                      >
                        <b-form-select 
                          class="text-center"
                          style="max-width: 100px;"
                          :value="item.quantity"
                          :data-line-id="item.line_id"
                          @change.native="updateClaimQuantity"
                          :disabled="false"
                          :class="{'bg-warning': isSomethingClaimed(item.line_id)}"
                        >
                          <!-- <b-form-select-option v-for="amount in (item.quantity+1)" :key="`${item.line_id}-${amount}`" :value="amount - 1">
                            {{ amount - 1 }}
                          </b-form-select-option> -->
                          
                          <!-- alex changed -->
                           <b-form-select-option v-for="amount in parseInt(item.quantity)+1" :key="`${item.line_id}-${amount}`" :value="amount - 1">
                            {{ amount-1 }}
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>    
                    </template>
                  </JobContentWithSlot>
                </b-card-text>
              </b-card>
            </transition>

            <!-- STEP 3 - Images -->
            <transition name="fade">
              <b-card v-if="claim.reason" border-variant="light" header="STEP 3" header-class="font-weight-bold" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="" >
                <h5>Upload images</h5>
                <p>You are in most cases required to provide photo evidence that support the claim. Transport label, package, products and possible flaws must be clearly visible in the pictures.</p>

                <!-- file input with data to base64 on input -->
                <BaseFile64Select v-if="!claim.no_images" v-model="claim.images" multiple />

                <!-- no images switch -->
                <b-form-group v-if="claim.images.length == 0" class="mt-3" description="For rushed production where photos is not currently available. Photos may be requested at a later stage.">
                  <b-checkbox v-model="claim.no_images">Create claim without photos</b-checkbox>
                </b-form-group>

                <!-- images from claim.images -->
                <transition-group name="list" tag="div" class="row">
                  <div v-for="image in claim.images" :key="`claim-image-${image.id}`" class="xxx col-sm-12 col-md-6 mb-2">
                    <div class="position-relative">
                      <b-img :src="image.source" thumbnail class="w-100"></b-img>
                      <div><small class="text-muted">{{ image.name }}</small></div>
                      <b-button variant="danger" class="close-button" size="sm" @click="removeClaimImageById(image.id)">
                        <small>Remove</small>
                      </b-button>
                    </div>
                  </div>
                </transition-group>

              </b-card>
            </transition>

            <!-- End Warning & Submit Button -->
            <div class="my-5">
              <BaseWarning v-if="warningMessage && warningMessage !== true" class="mb-2">
                <b-icon icon="exclamation-triangle-fill" class="text-warning mr-2"></b-icon> {{ warningMessage }}
              </BaseWarning>
              <b-button variant="primary" size="lg" block :disabled="warningMessage !== false" @click="submitClaim">Submit Claim</b-button>
            </div>

          </div>
        </div>
      </div>
       
      <pre v-if="false">{{ job }}</pre>
    </div><!-- end details-content -->

  </div><!-- end wrapper -->
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import JobContentWithSlot from '@/components/JobContent/JobContentWithSlot.vue';

export default {
  name: 'NewClaim',

  components: {
    JobContentWithSlot,
  },

  data() {
    return {
      loading: true,  // Page loading
      error: false,   // Page Error

      job: null,      // Current order (loaded on full)

      claim: {        // Form data
        reason: null,
        refund: false,
        sub_reasons: [],
        comment: '',
        images: [],
        items: [],
        no_images: false,
      }
    }
  },

  // Fetch JOB when created
  created () {
    this.fetchJobData();
  },

  computed: {
    ...mapGetters([
      'hasPendingRequests',
    ]),

    // USER MODULE
    ...mapGetters('user', [
      'userHasAnyOfRoles',
    ]),

    //
    // Job ID from route
    //
    job_id() { 
      return this.$route.params.order_id;
    },

    //
    // Reasons to choose from - Move to Claim module later
    //
    reason_options() {
      return [
        {value: 'lost_transport', text: 'Lost in transit'},
        {value: 'transport_damage', text: 'Damaged during transport'},
        {value: 'defect_packaging', text: 'Production defects'},
        {value: 'wrong_packaging', text: 'Wrong packaging'},
      ]
    },

    //
    // Sub reason - something depending on main reason
    //
    sub_reason_options() {
      if ( ! this.claim.reason ) return [];

      return [
        {value: 'smear', text: 'Smeared'},
        {value: 'cut', text: 'Cut'},
        {value: 'shattered', text: 'Shattered'},
        {value: 'exploded', text: 'Exploded'},
        {value: 'other', text: 'Other'},
      ]
    },

    
    //
    // Are there any warnings?
    // - FALSE: all ok
    // - TRUE: Hidden Warnings exist
    // - String: String of warning
    //
    warningMessage() {
      // No reason selected
      if ( ! this.claim.reason ) {
        return true; //'A reason must be selected to continue.';
      }

      // No claimed items
      const claimedQuantity = this.claim.items.reduce(function(sum, current) { return sum + current.claim_quantity; }, 0);
      if ( claimedQuantity <= 0 ) {
        return 'No quantities selected, can not create claim.';
      }

      // No photo for certain reasons
      if ( this.claim.reason != 'lost_transport' && this.claim.images.length <= 0 && this.claim.no_images == false ) {
        return 'This claim needs a photo to be valid.';
      }

      return false;
    },
    
  },

  methods: {
    ...mapActions([
      'addPendingRequest',
      'removePendingRequest',
    ]),

    // JOB MODULE
    ...mapActions('job', [
      'getJobPromise',
    ]),

    //
    // Special update trigger from child-slot
    // - match the triggered element's 'data-line-id' against our claim/items and update claimed quantity.
    //
    updateClaimQuantity(e) {
      const quantity = Math.round(e.target.value);
      const id = e.target.getAttribute('data-line-id');
      // Hassle of getting things reactive
      const index = this.claim.items.findIndex(x => x.line_id == id);
      this.$set(this.claim.items, index, {...this.claim.items[index], claim_quantity: quantity});
    },
    
    //
    // Is at least one item's claim_quantity > 0 ?
    //
    isSomethingClaimed(line_id) {
      const item = this.claim.items.find(x => x.line_id == line_id);
      return item?.claim_quantity;
    },

    //
    //
    //
    resetClaimQuantities() {
      this.claim.items = this.job.fulldata.items.map((x) => { x.claim_quantity = x.quantity; return x });
    },

    //
    // Remove a claim image
    //
    removeClaimImageById(id) {
      const index = this.claim.images.findIndex(x => x.id == id);
      this.$delete(this.claim.images, index);
    },

    //
    // FETCH CURRENT JOB - FRESH DATA
    // - Also adds a claim_quantity for each of the items (later used to make user select)
    //
    fetchJobData() {
      // Reset all
      this.error = this.job = null;
      this.loading = true;
      // Fetch data via api
      this.getJobPromise(this.job_id)
      .then((result) => {
        if ( result.data.status === 'ok' ) {
          // Save job data
          this.job = result.data.job;
          // Save job items in claim items (this is what we modify on page)
          // - add a claim_quantity to the items to work with + the hassle of making this a reactive prop
          //this.claim.items = this.job.fulldata.items.map((x) => { x.claim_quantity = x.quantity; return x });
          this.resetClaimQuantities();
          this.loading = false;
        }
        if ( result.data.status !== 'ok' ) {
          this.error = result.data.message || 'Unknown error!';
          this.$toastIt(this.error, {variant: 'danger', autoHideDelay: 2000});
        }
      })
      .catch((error) => {
        let msg = `Could not retrieve job details! (` + error.message + `)`;
        this.$toastIt(msg, {variant: 'danger', autoHideDelay: 2000});
        this.error = msg;
      });
    },

    submitClaim() {
      console.log( this.claim );
      this.$toastIt('Printed to console!', {variant: 'info', autoHideDelay: 2000});
    }
  },

}
</script>

<style lang="scss" scoped>
  .close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0.8;
  }

  /**
   * Transitions
   */
  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>