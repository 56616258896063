<template>
  <div class="claims_page">

    <!-- grey intro -->
    <div class="bg-site-light pt-4 pb-4 mb-4">
      <div class="container-fluid px-5">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
          <div class="flex-sm-fill">
            <h1 class="h3 font-w700 mb-0">
              Claims – Overview
            </h1>
<!--            <h2 class="h6 font-w500 text-muted mb-0">
              Submit new Claims via the action menu on the Job page.
            </h2>-->
          </div>
          <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
            <!--
            <a href="javascript:void(0)" class="btn btn-outline-secondary mr-1"><b-icon icon="plus-square-fill"></b-icon> New Order</a>
            <a href="javascript:void(0)" class="btn btn-secondary"><b-icon icon="gear-fill"></b-icon></a>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="px-md-3">
      <div class="container-fluid pt-3 pb-1 mb-5 bg-white shadow">
        <ClaimTable :filters="{}" />
      </div>
    </div>

    

  </div>
</template>



<script>
import { mapGetters } from 'vuex'

import ClaimTable from '@/components/ClaimTable/ClaimTable'

export default {
  components: {
    ClaimTable
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters([
      //'timestampAsDate',
      //'countryByCode',
    ]),
    ...mapGetters('user', [
      'userHasRole',
    ]),
  },

  created() {
    // Set Sidebar Active Part
    this.$store.commit("SET_SITE_SETTINGS", {'sidebar_current': 'claims'});
  },

  methods: {}
}
</script>

<style lang="css">
</style>