<template>
  <div class="claims-table">

    <!-- Filters -->
    <div class="card p-2 pt-3 bg-light border rounded-0">
      
      <b-row>
        <!-- Left Column -->
        <b-col class="my-1" xl="6" lg="6" md="12" >
          
          <!-- Accounts -->
          <b-form-group
            v-if="userAccounts.length > 1"
            label="Account"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <multiselect 
              id="account-select"
              v-model="table_filters.account"
              trackBy="account"
              label="name"
              size="sm"
              :options="userAccounts"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Select to filter..."
              @input="table_filters.currentPage=1; refreshTableData();"
            ></multiselect>
          </b-form-group>

          <!-- From date -->
          <b-form-group
            label="From"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="table_filters.from_date" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" @input="table_filters.currentPage=1; refreshTableData()"></b-form-datepicker>
          </b-form-group>

          <!-- To date -->
          <b-form-group
            label="To"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="table_filters.to_date" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" @input="table_filters.currentPage=1; refreshTableData()"></b-form-datepicker>
          </b-form-group>
        </b-col>
        
        <!-- Right Column -->
        <b-col class="my-1 align-self-end" xl="6" lg="6" md="12">

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-select :options="table_status_filters" v-model="table_filters.status" size="sm" value-field="id" text-field="text" @change="table_filters.currentPage=1; refreshTableData()"/>
          </b-form-group>

          <!-- Search -->
          <b-form-group
            label="Search"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-input-group class="ml-auto">
              <b-input v-model="table_filters.search" size="sm" placeholder="Filter table..." debounce="400" v-on:update="table_filters.currentPage=1; refreshTableData(true)"/>
              <b-input-group-append>
                <b-button size="sm" v-on:click="refreshTableData(true)"><b-icon icon="arrow-clockwise"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

        </b-col>
      </b-row>
      
    </div>

    <!-- Job Table -->
    <b-table
      ref="table"
      responsive
      show-empty
      emptyText="There are no claims to show"
      :hover="false"
      :outlined="false"
      :borderless="false"

      primary-key="id"
      :items="tabledataProvider"
      :fields="table_fields"
      :tbody-tr-class="rowClass"

      :per-page="table_filters.perPage"
      :current-page="table_filters.currentPage"

      :busy.sync="table_settings.isBusy"
      :no-local-sorting="true"
      :sort-by.sync="table_filters.sortBy"
      :sort-desc.sync="table_filters.sortDesc"
    >
      <!-- custom formatted columns -->
      <template #cell(jobid)="data">
        <b-link :to="{name:'JobDetails', params: { job_id: data.item.job_id }}">{{data.item.jobid}}</b-link>
      </template>

      <template #cell(replacement_jobid)="data">
        <span v-if="! data.item.replacement_jobid">-</span>
        <b-link v-else :to="{name:'JobDetails', params: { job_id: data.item.replacement_job_id }}">{{data.item.replacement_jobid}}</b-link>
      </template>

      <template #cell(no)="data">
        <b-link :to="{name:'individualClaim', params: { claim_id: data.item.id }}">{{data.item.no}}</b-link>
      </template>

      <template #cell(account)="data">
        {{ userAccountName(data.item.account) }}
      </template>

      <template #cell(status)="data">
        {{ statusNameById(data.item.status) }}
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | moment("Y-MM-DD HH:mm") }}
      </template>

      <!-- Row Details Toggle --
      <template #cell(actions)="row">
        <b-button size="sm" @click="row.toggleDetails" class="">
          <b-icon icon="chevron-down" v-if="!row.detailsShowing"></b-icon>
          <b-icon icon="chevron-up" v-else></b-icon>
        </b-button>
      </template>-->

      <!-- Row Details --
      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col sm="3" class="text-sm-right"><b>Warnings:</b></b-col>
            <b-col><div v-for="(warning, wkey) in row.item.fulldata_warnings" :key="`${row.item.id}-warning-${wkey}`">{{ warning.text }}</div></b-col>
          </b-row>
          <b-row>
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>
        </b-card>
      </template>-->

    </b-table>

    <!-- table pagination -->
    <b-row>
      <b-col sm="6" md="6" lg="4" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-1"
        >
          <b-form-select
            id="per-page-select"
            v-model="table_filters.perPage"
            size="sm"
            :options="table_settings.pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="6" md="6" lg="4" offset-sm="0" offset-md="0" offset-lg="4" class="my-1">
        <b-pagination
          v-model="table_filters.currentPage"
          :total-rows="table_settings.total_rows"
          :per-page="table_filters.perPage"
          align="fill"
          size="sm"
        >
        </b-pagination>
      </b-col>
    </b-row>    

  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClaimTable',
  components: {},

  props: {
    settings: {
      type: null,
      default: function () {
        return {}
      }
    },
    filters: {
      type: null,
      default: function () {
        return {}
      }
    }
  },

  data() {
    return {
      
      table_settings: {
        isBusy: false,
        total_rows: 9999,
        pageOptions: [10, 15, 30],
      },
      table_filters: { // Specify also here to make it responsive...
        currentPage: 1,
        perPage: 10,
        sortBy: 'created_at',
        sortDesc: true,
        search: '', // - since we wrote our own
        status: null,
        account: null,
      },
      items:[],
      // Table columns
      table_fields_template: [
        {
          key: 'no',
          label: 'Claim #',
          sortable: true,
        },        {
          access: {
            groups: [ // -or- definitions, only show for users with all roles from any of the groups
              ['superadmin'],
              ['puadmin'],
            ],
          },
          key: 'account',
          label: 'Account',
          sortable: true,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          key: 'jobid',
          label: 'Source Job',
          sortable: true,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          key: 'replacement_jobid',
          label: 'Replacement Job',
          sortable: true,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        /*
        {
          key: 'actions',
          label: '',
        },
        */
      ],

      // Filter: Status
      table_status_filters: [
        {id: null, text: 'Show all...' },
        {id: 'open', text: 'Open'},
        {id: 'pending', text: 'Pending'},
        {id: 'resolved', text: 'Resolved'},
        {id: 'on-hold', text: 'On Hold'},
        {id: 'rejected', text: 'Rejected'},
      ],
    }
  },

  async created() {
    let filters = this.getPageSettings( this.$route.name )
    this.table_settings = Object.assign({}, this.table_settings, this.settings)
    this.table_filters = Object.assign({}, this.table_filters, this.filters, filters)
  },

  computed: {
    ...mapGetters([
      'getPageSettings',
      'timestampAsDate'
    ]),
    ...mapGetters('user', [
      'userAccounts',
      'userAccountName',
      'userHasRole',
      'userHasAnyOfRoles',
      'userHasAllOfRoles'
    ]),
    ...mapGetters('claim', [
      //'printerapiStatusName'
    ]),
    
    //
    // Hide columns based on access-array specified on fields-template.
    // - minimal test squeeze
    // ! LATEST VERION FOR TEST IS THIS ONE !
    // - maybe a little overkill in this version ?
    //
    table_fields() {
      // full definition of fields
      let fields = this.table_fields_template;

      // filter fields based on access.groups logic
      fields = fields.filter( x => {
        // - include field, if no restrictions apply
        if ( ! x?.access || ! x.access?.groups ) return true;
        // - include field, if user has all roles in any of the access groups
        return x.access.groups.filter( group_roles => this.userHasAllOfRoles( group_roles ) ).length
      });
      
      // return filtered fields
      return fields;
    }
  },

  methods: {
    ...mapActions([
      'savePageSettings',
    ]),

    ...mapActions('claim', [
      'queryClaimsPromise',
    ]),

    refreshTableData() {
      this.$refs.table.refresh()
    },

    tabledataProvider(ctx){
      const queryObject = {
        'account': this.table_filters.account?.account ?? null, // multiselect does only return full selected object
        'status': this.table_filters.status ?? null,
        'search': this.table_filters.search ?? null,
        'from_date': this.table_filters.from_date ?? null,
        'to_date': this.table_filters.to_date ?? null,
        'sortBy': ctx.sortBy,
        'sortDesc': ctx.sortDesc,
        'perPage': ctx.perPage,
        'currentPage': ctx.currentPage,
      };

      // Save filters, instead of with watcher
      this.savePageSettings({id: this.$route.name, settings: this.table_filters });

      const promise = this.queryClaimsPromise(queryObject)
      return promise.then(response => {
        this.table_settings.total_rows = response?.data?.total ?? 0;
        console.log(response.data)
        this.items = response?.data?.items ?? [];
        return response?.data?.items ?? [];
      })
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item?.warnings_active_public) return 'table-warning'
      if (item?.deleted) return 'table-danger'
    },

    statusNameById(id) {
      return this.table_status_filters.find(x => x.id == id)?.text
    }
  },
}
</script>

<style lang="scss" scoped>
</style>